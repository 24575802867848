<script setup>
import { computed, inject } from 'vue';
import { useShiftStore } from '@/stores/shift';

const props = defineProps({
    date: {},
    selected: Object,
    currentMonth: Object,
    todaysDate: Boolean,
});

const emits = defineEmits(['dateWasClicked']);
const store = useShiftStore();
const dayjs = inject('dayjs');
const today = dayjs();

const dateIsBeforeMonth = computed(() => props.date.isBefore(store.startOfMonth));
const dateIsAfterMonth = computed(() => props.date.isAfter(store.endOfMonth));
</script>

<template>
    <template v-if="!dateIsBeforeMonth && !dateIsAfterMonth && date > today">
        <button
            type="button"
            @click="emits('dateWasClicked')"
            class="relative flex flex-col items-center justify-center overflow-hidden transition-colors duration-300 bg-white border-r cursor-pointer  border-gray-medium"
        >
            <div
                :class="{ 'bg-blue text-white rounded-full': selected }"
                class="
                    flex
                    justify-center
                    items-center
                    text-xs
                    w-[32px]
                    h-[32px]
                    hover:bg-blue hover:text-white
                    rounded-full
                "
            >
                <span>{{ date.format('D') }} </span>
            </div>
        </button>
    </template>
    <template v-else>
        <span
            class="
                relative
                flex flex-col
                items-center
                justify-center
                overflow-hidden
                transition-colors
                duration-300
                text-xs
                bg-[#F9FAFB]
                border-r border-gray-medium
            "
        >
            <span class="text-blue">{{ date.format('D') }}</span>
        </span>
    </template>
</template>